import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SEO from 'src/components/seo'
import { PageContainer, PageTitle, PageContent } from './styles'

const ShopifyPage = ({ data }) => {
  const { page } = data.shopifyStorefront
  return (
    <>
      <SEO title={page.title} path={`/pages/${page.handle}/`} description={page.bodySummary} />
      <PageContainer>
        <PageTitle>{page.title}</PageTitle>
        <PageContent dangerouslySetInnerHTML={{ __html: page.body }} />
      </PageContainer>
    </>
  )
}

export const shopifyPageQuery = graphql`
  query ($handle: String!) {
    shopifyStorefront {
      page(handle: $handle) {
        id
        title
        body
        bodySummary
        handle
      }
    }
  }
`

ShopifyPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ShopifyPage
